.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 1.5rem; 
  height: 5em;
  background-color: var(--background-color);
  box-shadow: 0 2px 5px var(--shadow-color);
}

.nav {
  display: flex;
  justify-content: space-between;
  gap: 1rem; 
  flex-grow: 1;
  max-width: 80em; 
}

.navLink {
  text-decoration: none;
  color: var(--text-color);
  font-size: clamp(1.5rem, 2vw, 2rem); 
  transition: color 0.3s ease;
  cursor: pointer;
  flex-basis: auto; 
}

.navLink:hover {
  color: var(--accent-color);
}

.navLink:active {
  color: var(--button-click-background);
}

.dropdownContainer {
  position: relative;
}

.dropdownMenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modalContent {
  position: relative;
  background-color: var(--background-color);
  border-radius: 1rem;
  padding: 2rem;
  box-shadow: 0 2px 10px var(--shadow-color);
  z-index: 1001;
  max-width: 500px;
  width: 90%;
}

.modalTitle {
  font-size: clamp(1.75rem, 2.5vw, 2rem);
  font-weight: bold;
  color: var(--text-color);
  text-align: center;
  margin-bottom: 1.5rem;
}

.dropdownLink {
  display: block;
  padding: 1rem 0;
  text-align: center;
  text-decoration: none;
  color: var(--text-color);
  font-size: clamp(1.2rem, 2.5vw, 1.5rem);
  transition: background-color 0.3s ease, color 0.3s ease;
}

.dropdownLink:hover {
  background-color: var(--hover-color);
  color: var(--accent-color);
}

.closeButton {
  background-color: var(--button-background);
  color: var(--button-text-color);
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  margin-top: 2rem;
  transition: background-color 0.3s ease;
}

.closeButton:hover {
  background-color: var(--button-hover-background);
}

.closeButton:active {
  background-color: var(--button-click-background);
}

@media (max-width: 768px) {
  .header {
    padding: 0.5rem 1rem; 
  }

  .nav {
    gap: 0.75rem; 
  }

  .navLink {
    font-size: clamp(1.2rem, 3vw, 1.5rem); 
  }

  .modalContent {
    max-width: 90%;
    padding: 1.5rem;
  }

  .modalTitle {
    font-size: clamp(1.5rem, 2.5vw, 1.75rem);
  }

  .dropdownLink {
    font-size: clamp(1rem, 3vw, 1.25rem);
  }
}
