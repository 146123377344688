  .aboutContainer {
    padding: 2rem 1rem;
    color: var(--text-color);
    max-width: 60em;
    margin: 0 auto;
  }
  
  .title {
    font-size: 2.5rem;
    color: var(--text-color);
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  .missionStatement {
    font-size: 1.2rem;
    color: var(--text-color-muted);
    margin-bottom: 2rem;
    text-align: center;
  }
  
  .paragraph {
    font-size: 1rem;
    line-height: 1.6;
    color: var(--text-color);
    margin-bottom: 1.5rem;
  }
  
  @media (min-width: 768px) {
    .aboutContainer {
      padding: 4rem 2rem;
    }
  
    .title {
      font-size: 3rem;
    }
  
    .missionStatement {
      font-size: 1.5rem;
    }
  
    .paragraph {
      font-size: 1.2rem;
    }
  }
  