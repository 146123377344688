.newsletterContainer {
    background-color: var(--background-color);
    color: var(--text-color);
    text-align: center;
    padding: 3rem 1rem;
    max-width: 50em; 
    margin: 0 auto; 
  }
  
  .title {
    font-size: 2rem; 
    font-weight: 600; 
    margin-bottom: 1.5rem; 
  }
  
  .description {
    color: var(--text-color-muted);
    margin-bottom: 2rem; 
    font-size: 1.1rem; 
  }
  
  .form {
    display: flex;
    flex-direction: column; 
    align-items: center;
    gap: 1rem;
  }
  
  .input {
    border: 1px solid var(--border-color);
    padding: 0.75rem;
    width: 100%; 
    max-width: 25em; 
    border-radius: 2rem; 
    font-size: 1rem;
  }
  
  .input::placeholder {
    color: var(--text-color-muted);
  }
  
  .button {
    background-color: var(--button-background);
    color: var(--button-text-color);
    padding: 0.75rem 2rem;
    border: 1px solid var(--border-color);
    border-radius: 2rem; 
    font-size: 1rem;
    text-transform: uppercase; 
    font-weight: 500;
    cursor: pointer;
  }
  
  .button:hover {
    background-color: var(--button-hover-background);
  }
  
  .button:active {
    background-color: var(--button-click-background);
  }
  
  .status {
    margin-top: 1.5rem;
    color: var(--accent-color);
    font-size: 1.1rem; 
    font-weight: 500; 
  }
  