.volunteerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    background-color: var(--background-color);
    color: var(--text-color);
    text-align: center;
  }
  
  .text {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .iconLink {
    color: var(--button-background);
    transition: color 0.3s ease;
  }
  
  .icon {
    font-size: 3rem;
  }
  
  .iconLink:hover {
    color: var(--button-hover-background);
  }

  .iconLink:active {
    color: var(--button-click-background);
  }
  