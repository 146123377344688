.safetyContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    background-color: var(--background-color);
    color: var(--text-color);
    box-shadow: 0px 4px 6px var(--shadow-color);
    border: 1px solid var(--border-color);
    gap: 2rem;
  }
  
  .textSection {
    flex: 1;
    max-width: 600px;
  }
  
  .title {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .list {
    list-style: none;
    padding: 0;
  }
  
  .listItem {
    margin-bottom: 1rem;
    font-size: 1.2rem;
    line-height: 1.5;
  }
  
  .imageSection {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image {
    max-width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  @media (max-width: 768px) {
    .safetyContainer {
      flex-direction: column;
      gap: 1rem;
      padding: 1rem;
    }
  
    .textSection {
      text-align: center;
    }
  
    .title {
      font-size: 1.5rem;
    }
  
    .listItem {
      font-size: 1rem;
    }
  }
  