.homeBannerContainer {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
  }
  
  .homeBannerImage {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .homeBannerTextOverlay {
    position: absolute;
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%); 
    width: 100%;
    background: rgba(0, 0, 0, 0.5); 
    padding: 1rem 2rem;
    text-align: center;
    color: var(--hover-color);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .homeBannerTitle {
    font-size: clamp(2rem, 5vw, 3rem); 
    margin: 0;
    text-shadow: var(--shadow-color);
  }
  
  .homeBannerSubtitle {
    font-size: clamp(1rem, 4vw, 1.8rem);
    margin-top: 1rem;
    text-shadow: var(--shadow-color);
  }
  
  /* Adjustments for larger screens */
  @media (min-width: 769px) {
    .homeBannerTextOverlay {
      padding: 2rem;
    }
  }
  
  /* Adjustments for mobile screens */
  @media (max-width: 768px) {
    .homeBannerTextOverlay {
      padding: 1.5rem;
    }
  
    .homeBannerTitle {
      font-size: clamp(1.8rem, 5vw, 2.2rem); 
    }
  
    .homeBannerSubtitle {
      font-size: clamp(0.9rem, 4vw, 1.2rem); 
    }
  }
  