.contactPage {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  .formDescription {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 1.5rem;
    color: var(--text-color);
    max-width: 600px;
  }
  
  .formContainer {
    width: 100%;
    max-width: 50em;
    margin: 0 auto;
  }
  
  @media (max-width: 768px) {
    .formDescription {
      font-size: 1.25rem;
    }
  }
  
  @media (max-width: 480px) {
    .formDescription {
      font-size: 1rem;
    }
  }
  