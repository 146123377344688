.missionSection {
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 4em 1em;
    color: var(--text-color);
    text-shadow: var(--shadow-color) 1px 1px 1px;
}

.contentContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin: 0 auto;
}

.leftSection {
    flex: 1;
    margin-right: 4vw;
}

.rightSection {
    flex: 1;
    margin-left: 4vw;
}

.heading {
    font-size: 4vw;
    font-weight: bold;
    margin: 0;
    line-height: 1.2;
}

.subheading {
    font-size: 3.6vw;
    font-weight: bold;
    margin-bottom: 1vw;
}

.missionText {
    font-size: 2.4vw;
    line-height: 1.6;
}

@media (max-width: 768px) {
    .contentContainer {
        flex-direction: column;
        padding: 3vw;
    }

    .leftSection {
        margin-right: 0;
        margin-bottom: 3vw;
    }

    .rightSection {
        margin-left: 0;
    }

    .heading {
        font-size: 6vw;
    }

    .subheading {
        font-size: 4.8vw;
    }

    .missionText {
        font-size: 3.2vw;
    }
}

@media (max-width: 480px) {
    .heading {
        font-size: 7vw;
    }

    .subheading {
        font-size: 5vw;
    }

    .missionText {
        font-size: 3.5vw;
    }
}
