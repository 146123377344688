.footer {
    background: var(--background-color);
    color: var(--text-color);
    border-top: 2px solid var(--border-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2em;
    position: relative;
    width: 100%;
    font-size: 1.4em; 

    @media (max-width: 768px) {
        font-size: 1.2em;
        padding: 1.5em;
    }

    @media (max-width: 480px) {
        font-size: 1em;
        padding: 1em;
    }
}

.topLeft {
    position: absolute;
    top: 1em;
    left: 1em;
    font-size: 1em;

    @media (max-width: 768px) {
        font-size: 0.9em;
        top: 0.5em;
        left: 0.5em;
    }

    @media (max-width: 480px) {
        font-size: 0.8em;
        top: 0.3em;
        left: 0.3em;
    }
}

.mutedText {
    color: var(--text-color-muted);
    margin: 0;
}

.linksRow {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 1.5em;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        margin-bottom: 1em;
    }
}

.column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 2em;

    @media (max-width: 768px) {
        align-items: center;
        margin: 1em 0;
    }
}

.title {
    font-weight: bold;
    margin-bottom: 0.5em;
}

.link {
    color: var(--text-color);
    text-decoration: none;
    margin: 0.5em 0;

    @media (max-width: 768px) {
        margin: 0.4em 0;
    }

    @media (max-width: 480px) {
        margin: 0.3em 0;
    }
}

.link:hover {
    color: var(--accent-color);
}

.footerText {
    text-align: center;
    margin: 0;
    padding: 0.5em 0;
    font-size: 1em; 

    @media (max-width: 768px) {
        font-size: 0.9em;
    }

    @media (max-width: 480px) {
        font-size: 0.8em;
    }
}

.copyright {
    margin-top: 1em;
    text-align: center;
    width: 100%;
}
