.contactForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.4); 
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px); 
  -webkit-backdrop-filter: blur(10px); 
}

.contactFormTitle {
  margin-bottom: 2rem;
  text-align: center;
  color: var(--text-color);
}

.formGroup {
  margin-bottom: 1.5rem;
  width: 100%;
}

.labelWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.label {
  font-size: 1rem;
  color: var(--text-color);
}

.required {
  margin-left: 0.25rem;
  color: red;
}

.inputField {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid var(--border-color);
  border-radius: 3em; 
  outline: none;
  background-color: rgba(255, 255, 255, 0.4); 
  color: var(--text-color);
}

.inputField:focus {
  border-color: var(--accent-color);
  background-color: rgba(255, 255, 255, 0.6); 
}

.radioGroup, .checkboxGroup {
  display: flex;
  flex-direction: column;
}

.checkboxGroup {
  gap: 0.5rem;
}

.radioLabel, .checkboxLabel {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  color: var(--text-color);
}

.radioLabel input, .checkboxLabel input {
  margin-right: 0.5rem; 
}

.error {
  color: red;
  font-size: 0.875rem;
  margin-left: 0.5rem;
}

.responseMessage {
  margin-top: 1rem;
  font-size: 1rem;
  color: var(--text-color);
}

.submitButton {
  background-color: var(--button-background);
  color: var(--button-text-color);
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 3em; 
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  margin-top: 1rem;
  box-shadow: 0 2px 5px var(--shadow-color);
}

.submitButton:disabled {
  background-color: var(--button-hover-background);
  cursor: not-allowed;
}

.submitButton:hover:enabled {
  background-color: var(--button-hover-background);
  box-shadow: 0 4px 10px var(--shadow-color); 
}

.submitButton:active:enabled {
  background-color: var(--button-click-background);
}
