.videoPlayerContainer {
    position: relative;
    width: 100%;
    max-width: 100%;
    padding: 2rem;
    background-color: var(--background-color);
  }
  
  .videoWrapper {
    position: relative;
    width: 100%;
    padding-top: 56.25%; 
    border: 1px solid var(--border-color);
    border-radius: 0.5rem;
    overflow: hidden;
  }
  
  .videoWrapper iframe,
  .videoWrapper video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .videoSpacing {
    margin: 2rem 0; 
  }
  