.meetContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
  background-color: var(--background-color);
}

.title {
  font-size: clamp(2rem, 4vw, 3rem); 
  color: var(--text-color);
  text-align: center;
  margin-bottom: 2rem;
}

.memberContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  padding: 2rem;
  border-bottom: 1px solid var(--border-color);
}

.textArea {
  flex: 1;
  text-align: left;
}

.name {
  color: var(--accent-color);
  font-size: clamp(1.8rem, 2.5vw, 2rem);
  margin-bottom: 1rem;
}

.bio {
  font-size: clamp(1rem, 1.8vw, 1.2rem); 
  line-height: 1.6;
  margin-bottom: 1rem;
  color: var(--text-color);
}

.imageArea {
  flex-shrink: 0;
}

.image {
  width: 20em;
  height: 20em;
  border-radius: 50%;
  border: 1px solid var(--border-color);
  object-fit: cover;
}

/* Adjust for mobile screens */
@media (max-width: 768px) {
  .meetContainer {
    padding: 0.5rem; 
  }

  .memberContainer {
    flex-direction: column-reverse; 
    text-align: center;
    gap: 0.5rem; 
    padding: 0.5rem;
  }

  .textArea {
    padding: 0 0.5rem; 
  }

  .name {
    margin-bottom: 0.5rem; 
  }

  .bio {
    margin-bottom: 0.5rem;
  }

  .imageArea {
    margin-bottom: 1rem;
  }

  .image {
    margin: 0 auto;
    width: 15em; 
    height: 15em;
  }
}
