.resourcesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  text-align: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: var(--text-color);
  min-height: 100vh; 
}

.title {
  font-size: clamp(1.8rem, 2.5vw, 2.5rem); 
  margin-bottom: 1rem;
  color: var(--accent-color);
}

.description {
  font-size: clamp(1.1rem, 1.8vw, 1.4rem); 
  margin-bottom: 2rem;
  max-width: 600px;
  line-height: 1.6;
}

.resourcesList {
  width: 100%;
  max-width: 600px;
}

.resource {
  margin-bottom: 2rem;
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.resource:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.resourceName {
  font-size: clamp(1.3rem, 2vw, 1.8rem); 
  color: var(--text-color);
  margin-bottom: 0.5rem;
}

.resourceInfo {
  font-size: clamp(1rem, 1.5vw, 1.2rem); 
  color: var(--text-color-muted);
  margin-bottom: 0.5rem;
}

.link {
  color: var(--accent-color);
  text-decoration: none;
  word-wrap: break-word; 
}

.link:hover {
  text-decoration: underline;
}

/* Responsive styles for mobile */
@media (max-width: 768px) {
  .resourcesContainer {
    padding: 1rem;
  }

  .title {
    font-size: 1.5rem;
  }

  .description {
    font-size: 1rem;
  }

  .resource {
    padding: 1rem;
    margin-bottom: 1.5rem;
  }

  .resourceName {
    font-size: 1.2rem;
  }

  .resourceInfo {
    font-size: 0.95rem;
  }
}
