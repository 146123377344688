.blogContainer {
  padding: 2rem;
  background-color: var(--background-color);
  color: var(--text-color);
}

.masonryGrid {
  display: flex;
  margin-left: -30px; 
  width: auto;
}

.masonryGridColumn {
  padding-left: 30px;
  background-clip: padding-box;
}

.blogItem {
  margin-bottom: 30px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border: 1px solid var(--border-color);
  box-shadow: 0px 4px 10px var(--shadow-color);
  transition: transform 0.3s ease;
}

.blogItem:hover {
  transform: scale(1.03);
}

.blogImage {
  width: 100%;
  height: auto;
  display: block;
}

.blogTitle {
  font-size: 1.5rem;
  margin: 1rem;
  color: var(--text-color);
  text-align: center;
}

.blogIntro {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 2rem;
  color: var(--text-color);
  text-align: center;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

/* Modal styling */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden; 
}

.modalContent {
  background-color: var(--background-color);
  padding: 2rem;
  border-radius: 8px;
  max-width: 600px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
}

/* Wider modal for desktop viewports */
@media (min-width: 1024px) {
  .modalContent {
    max-width: 900px; /* Increase the width for desktop */
  }
}

.modalAuthor {
  font-size: 1rem;
  color: var(--text-color-muted);
  margin-top: 1rem;
  text-align: right;
  font-style: italic;
}

/* Close button moved to the top right of the page */
.closeButton {
  position: fixed;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: var(--text-color-muted);
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 1100; 
}

.modalImage {
  width: 100%;
  max-width: 100%; 
  height: 30em;
  border-radius: 8px;
  object-fit: contain;
}


.modalTitle {
  font-size: 2rem;
  margin: 1rem 0;
  color: var(--text-color);
}

.modalText {
  font-size: 1rem;
  line-height: 1.6;
  color: var(--text-color-muted);
}

/* For smaller screens */
@media (max-width: 768px) {
  .modalContent {
    padding: 1.5rem;
  }

  .modalTitle {
    font-size: 1.5rem;
  }

  .modalText {
    font-size: 0.9rem;
  }

  /* Adjust close button for smaller screens */
  .closeButton {
    font-size: 1.25rem;
  }
}
