.helpContainer {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    background-color: var(--accent-color); 
    padding: 0.5rem 2.5rem;
    border-bottom: 2px solid var(--border-color);
  }
  
  .helpButton {
    background: none;
    border: none;
    color: var(--text-color);
    font-size: 1rem;
    cursor: pointer;
    text-decoration: none;
    padding: 0.5rem 1rem;
    transition: color 0.3s ease; 
  }
  
  .helpButton:hover {
    color: var(--hover-color);
  }
  
  .helpButton:active {
    color: var(--button-click-background);
  }
  