:root {
  --background-color: #fff; 
  --text-color: #333333;
  --text-color-muted: #777777; 
  --accent-color: #4287f5; 
  --hover-color: #e0f0ff; 
  --button-background: #4a90e2; 
  --button-text-color: #ffffff; 
  --button-hover-background: #357ab8; 
  --button-click-background: #2d5e96; 
  --border-color: #dddddd; 
  --shadow-color: rgba(0, 0, 0, 0.1); 
}

* {
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  box-sizing: border-box;
}